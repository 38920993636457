<template>
  <div class="sourceGroupRoot">
   <b-input-group :prepend="'팀(' + team.groupName + ')'" class="mt-3">
    <b-form-input v-model="memberInputString" type="text"></b-form-input>
       <b-input-group-append>
           <span>{{ this.memberNames.length }} 명 </span>
           <b-button variant="danger"  @click="deleteTeam(team)">팀삭제</b-button>
        </b-input-group-append>
    </b-input-group>
    
  </div>
</template>

<script>
export default {
  name: 'SourceGroup',
  props: {
    team: Object
  },
  data(){
    return {
      memberInputString:"",
      memberNames:[]  
    }
  },
  created(){
    this.memberInputString = this.team.members;
  },
  watch:{
    memberInputString(members){
      this.memberNames =  (members ? members.trim().split(','): []).map(name => name.trim()).filter(name => name.length >0 )
      this.$store.commit('updateMembers',{groupId: this.team.groupId, members:members});
    }
  },
  methods:{
   
    deleteTeam(team){
      this.$store.commit('deleteTeam',team.groupId);
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
