
import Vue from 'vue'
import Vuex from 'vuex'

const  keyForSrouceGroup = "KEY_TEAMS_4"

Vue.use(Vuex) 

function updateValidStatus(state){
  let hasGroup = state.sourceGroups && state.sourceGroups.length > 0;
  if(!hasGroup){
    state.isValidToCreateGroup = false;
  }else{
    let emptyGroup = state.sourceGroups.map(team => (team.members ? team.members.trim().split(','): []).map(name => name.trim()).filter(name => name.length >0 ))
                      .find(memberNames => memberNames.length == 0 );
    state.isValidToCreateGroup = emptyGroup ? false : true ;
  }
  return state;
}



function saveTeams(state){
  let data = state.sourceGroups ? JSON.stringify(state.sourceGroups) : "[]";
  localStorage.setItem(keyForSrouceGroup,data);
  state = updateValidStatus(state);
}

function loadTeams(){
  let data = localStorage.getItem(keyForSrouceGroup);
  if(data && data.length > 0){
    return JSON.parse(data);
  }else{
    return [];
  }
}


export default new Vuex.Store({
    state: {
      sourceGroups: [],
      isValidToCreateGroup:false
    },
    mutations:{
      prepareSourceGroup(state){
        let loadedSourceGroup = loadTeams();
        state.sourceGroups = loadedSourceGroup ?loadedSourceGroup: [];
        state = updateValidStatus(state);
        return state;
      },
      updateSourceGroup(state,groups) {
        state.sourceGroups = groups; 
        saveTeams(state);
        return state;
      },
      addNewTeam(state,newTeameName){
        let randomId = Math.random().toString(36).substr(2,11);
        state.sourceGroups.push( {groupId:randomId,groupName:newTeameName,members:"" });
        saveTeams(state);
        return state ;
      },
      deleteTeam(state,teamId){
        let newTeams = state.sourceGroups.filter( team => team.groupId != teamId );
        state.sourceGroups = newTeams;
        saveTeams(state);
        return state;
      },
      updateMembers(state,updatedTeam){
        let foundOne = state.sourceGroups.find( team => team.groupId == updatedTeam.groupId);
        foundOne.members = updatedTeam.members.trim();      
        saveTeams(state);
        return state;
      }

    },
    actions:{
      
    }
  });