

class RandomGrouper {
   

    makeGroup(needGroupCount,groups) {
        
        const defaultResult = [[]];
        const memberMap = groups?.map((group) => {
            return this.splitGroup(group.sort(() => Math.random() - 0.5),needGroupCount);
        }) ?? defaultResult;

        const result = Array.from({ length: needGroupCount }, () => []);
        memberMap.forEach((subGroupList) => {
            subGroupList.forEach((memberNames) => {
                const smallestGroup = result.reduce((acc, cur) => acc.length <= cur.length ? acc : cur);
                smallestGroup.push(...memberNames);
            });
        });
        return result;
    }

    splitGroup(collection, groupCount) {
        if (collection.length === 0) {
            return [];
        }

        const cnt = collection.length;
        var countPerGroup = Math.floor(cnt / groupCount);
        const tempResult = [];
        if(countPerGroup<1){
            countPerGroup = 1;
        }
        for (let i = 0; i < cnt; i += countPerGroup) {
            tempResult.push(collection.slice(i, i + countPerGroup));
        }

        if (tempResult.length <= groupCount) {
            return tempResult;
        } else {
            const result = [];
            for (let i = 0; i < groupCount; i++) {
                result.push([...tempResult[i]]);
            }

            const remainMembers = [];
            for (let i = groupCount; i < tempResult.length; i++) {
                remainMembers.push(...tempResult[i]);
            }

            remainMembers.forEach((e, index) => {
                result[index % groupCount].push(e);
            });
            return result;
        }
    }
}

export default new RandomGrouper();
