<template>
  <div class="gameRoot">

        <div class="teamAddPan">
          <b-input-group prepend="팀명" class="mt-3">
            <b-form-input v-model="tempTeamName" type="text"></b-form-input>
            <b-input-group-append>
            <b-button :variant="variantForAddNewTeamButton" :disabled="!isAddNewTeamEnabled" @click="addNewTeam()">팀 추가</b-button>
            </b-input-group-append>
        </b-input-group>
        </div>
   
      <div class="teamAddPan">
          <ul>
          <li v-for="it in this.$store.state.sourceGroups" v-bind:key="it.groupId">
            <SourceGroup :team="it" />
          </li>
        </ul>

        <b-form-select      v-model="targetTeamCount"      :options="targetTeamCountOptions"      class="mb-3"            disabled-field="notEnabled"    ></b-form-select>

        <b-button :variant="this.$store.state.isValidToCreateGroup ? 'primary':''" :disabled="!this.$store.state.isValidToCreateGroup" @click="createGroup()">팀 섞기</b-button>
      
      
        <div class="resultPan">
            <div v-for="(group,index) in resultGroup" v-bind:key=" index + '조'">
            {{ index+1 }} 조 ({{ group.length +"명" }}) : <span v-for="name in group"  v-bind:key=" index + '조' + name + Date.now()">{{ name }},</span>
          </div>
       </div>
      
      </div>

     
  </div>
</template>

<script>
import SourceGroup from './SourceGroup.vue'
import grouper from "../grouper"

export default {
  name: 'RandoGroup',
  props: {
    
  },
  components: {
    SourceGroup
  },
  data(){
    return {
      tempTeamName:"",
      isCreateTeamEnabled:false,
      targetTeamCount:8,
      targetTeamCountOptions: [],
      resultGroup:[]
    }
  },
  created(){
    this.$store.commit('prepareSourceGroup')
    this.isCreateTeamEnabled= this.$store.state.isCreateTeamEnabled;
    this.targetTeamCountOptions =[];
    for( var i=2 ;i<11;i++){
      this.targetTeamCountOptions.push( { text: i+"개 조", value: i+"" })
    }
  },
  computed:{
  
        isAddNewTeamEnabled: function() { 
            return this.tempTeamName && String(this.tempTeamName).length > 0;
        },
        variantForAddNewTeamButton: function() { 
          return this.isAddNewTeamEnabled ? 'primary' : '';
        }
  },

  methods:{
    addNewTeam(){
      let teamName = this.tempTeamName
      this.$store.commit('addNewTeam',teamName);
      this.tempTeamName = "";
      this.isCreateTeamEnabled= this.$store.state.isCreateTeamEnabled;
    },
    createGroup(){
      console.log("createGroup")
      let state = this.$store.state;
      let teams = state.sourceGroups.map(team => (team.members ? team.members.trim().split(','): []).map(name => name.trim()).filter(name => name.length >0 ).map(name=> name+"("+ team.groupName  +")" ))
      
      var repeatTimes = Math.floor(Math.random() * 10)+10;
      var repeatIndex = 0;
      let intervalId = setInterval(()=>{
          this.resultGroup = grouper.makeGroup(this.targetTeamCount, teams);
          repeatIndex++;
          if(repeatIndex>=repeatTimes){
            clearInterval(intervalId);
          }
      },100)
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.gameRoot{
  text-align: center;
}
.teamAddPan{
  max-width: 800px;
  text-align: center;
}
.resultPan{
  text-align: left;
  padding-left: 5%;
}
</style>
